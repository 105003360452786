import Pusher from 'pusher-js';

const APP_KEY = process.env.REACT_APP_PUSHER_KEY; // 'e71871c64aadff325edf';
const APP_CLUSTER = process.env.REACT_APP_PUSHER_CLUSTER; //  'ap2';
const BASE_URL = process.env.REACT_APP_BASE_URL;

const MyPusher = (token) => {
	return new Pusher(APP_KEY, {
		authEndpoint: `${BASE_URL}/broadcasting/auth`,
		cluster: APP_CLUSTER,
		encrypted: true,
		auth: {
			headers: {
				Accept: 'application/json',
				Authorization: 'Bearer ' + token,
			},
		},
	});
};

export default MyPusher;

export const AUCTION_BID_INTRO_TIMING = parseInt(process.env.REACT_AUCTION_BID_INTRO_TIMING);
